
import { Component, Vue } from 'vue-property-decorator';
import Navbar from '@/components/Navbar.vue';
import Converter from './components/Converter.vue';

@Component({
  components: {
    Navbar,
    Converter,
  },
})
export default class App extends Vue {
}
