
import { Component, Vue } from 'vue-property-decorator';
import { convertSassToScss } from '@/util/convertSassToScss';
import { convertScssToSass } from '@/util/convertScssToSass';
import { downloadTextAsFile } from '@/util/downloadTextAsFile';
import { uploadTextFile } from '@/util/uploadTextFile';

const DEFAULT_INPUT = `
@import "../styles/imports"

$col-primary: #f39900

=center_horizontal()
  display: flex
  justify-content: center

.container
  +center_horizontal()
  border: 1px solid darken($col-background, 10)
  .item
    color: $col-primary
`.trim();

const DEFAULT_INPUT_LANGUAGE = 'Sass';

@Component<Converter>({
  watch: {
    inputLanguage() {
      localStorage.setItem('sass-scss-converter_inputLanguage', this.inputLanguage);
    },
    input() {
      localStorage.setItem('sass-scss-converter_input', this.input);
    },
  },
})
export default class Converter extends Vue {
  public MALFORMED_INPUT_OUTPUT = 'malformed input...';

  public inputLanguage: 'Sass' | 'SCSS' = DEFAULT_INPUT_LANGUAGE;

  public input = DEFAULT_INPUT

  public output = '';

  public downloadFileName = 'style';

  get outputLanguage(): 'Sass' | 'SCSS' {
    return this.inputLanguage === 'Sass' ? 'SCSS' : 'Sass';
  }

  get showOutputButtons(): boolean {
    return !!(this.output && this.output.trim() && this.output !== this.MALFORMED_INPUT_OUTPUT);
  }

  created() {
    this.initializeInputLanguage();
    this.initializeInput();
    this.inputChange();
  }

  private initializeInputLanguage() {
    const storedInputLanguage = localStorage.getItem('sass-scss-converter_inputLanguage');
    if (storedInputLanguage === 'Sass' || storedInputLanguage === 'SCSS') {
      this.inputLanguage = storedInputLanguage;
    }
  }

  private initializeInput() {
    const storedInput = localStorage.getItem('sass-scss-converter_input');
    if (storedInput) {
      this.input = storedInput;
    }
  }

  async switchLanguages() {
    this.inputLanguage = this.outputLanguage;
    this.input = (this.output === this.MALFORMED_INPUT_OUTPUT ? '' : this.output).trim();
    await this.$nextTick();
    this.inputChange();
  }

  async inputChange() {
    try {
      if (this.inputLanguage === 'Sass') {
        this.output = await convertSassToScss(this.input);
      } else {
        this.output = await convertScssToSass(this.input);
      }
    } catch (e) {
      this.output = this.MALFORMED_INPUT_OUTPUT;
    }
  }

  async copyOutputToClipboard() {
    await this.$copyText(this.output);
    (this as any).$bvToast.show('clipboard-toast');
  }

  async uploadFile() {
    const { name, extension, content } = await uploadTextFile();
    if (extension !== this.inputLanguage.toLocaleLowerCase()) {
      await this.switchLanguages();
    }
    this.input = content;
    this.downloadFileName = name;
    await this.inputChange();
  }

  downloadOutputAsFile() {
    downloadTextAsFile(`${this.downloadFileName}.${this.outputLanguage.toLocaleLowerCase()}`, this.output);
  }
}
